import React from "react"
import { navigate } from "gatsby"
import tw from "twin.macro"
import styled from "@emotion/styled"
import Button from "../components/Button"
import { FaLongArrowAltLeft } from "react-icons/fa"
import * as Color from "../utils/color"

const Detail = styled.div`
	${tw`text-xl mb-4`}
	color: ${Color.primary_800};
`

const Header = styled.div`
	${tw`text-6xl leading-none mb-4`}
	font-weight: bold;
	color: ${Color.primary};
`

const Info = styled.div`
	${Button} {
		width: 250px;

		svg {
			${tw`mr-2`}
		}
	}
`

const NotFound = styled.section`
	text-align: center;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
`

const Wrapper = () => {
	return (
		<NotFound>
			<Info>
				<Header>404 Not Found</Header>
				<Detail>
					Don't type different things in your input bar? <br />
					So instead, use your mouse or something.
				</Detail>
				<Button onClick={() => navigate("/")} aria-label={"return home"}>
					<FaLongArrowAltLeft style={{ display: "inline" }} />
					Return Home
				</Button>
			</Info>
		</NotFound>
	)
}

export default Wrapper
